import React from 'react';
import {BuyerNote} from '../../Components/BuyerNote/BuyerNote';
import {CartItems} from '../../Components/CartItems/CartItems';
import {Coupon} from '../../Components/Coupon/Coupon';
import {Headline} from '../../Components/Headline/Headline';
import {OrderSummary} from '../../Components/OrderSummary/OrderSummary';
import {useControllerProps} from '../../ControllerContext';
import s from './CartBody.scss';
import {CheckoutButtons} from '../../Components/CheckoutButtons/CheckoutButtons';
import {useEnvironment} from 'yoshi-flow-editor-runtime';
import {CartScreenReaderMessage} from './CartScreenReaderMessage/CartScreenReaderMessage';
import {MinimumOrderAmount} from '../../Components/MinimumOrderAmount/MinimumOrderAmount';
import {SecureCheckout} from '../../Components/SecureCheckout/SecureCheckout';

export const CartBody = () => {
  const {shouldShowCoupon, shouldShowBuyerNote, shouldShowMinimumOrderAmount, shouldShowTopCheckoutButtonInMobile} =
    useControllerProps().cartStore;
  const {isMobile} = useEnvironment();

  return (
    <main className={s.main}>
      <section className={s.cart}>
        {isMobile && shouldShowTopCheckoutButtonInMobile && <CheckoutButtons isOnTop />}
        <Headline />
        <CartItems />
        <footer className={s.footer}>
          {shouldShowCoupon && (
            <div className={s.coupon}>
              <Coupon />
            </div>
          )}
          {shouldShowBuyerNote && (
            <div className={s.buyerNote}>
              <BuyerNote />
            </div>
          )}
        </footer>
        <CartScreenReaderMessage />
      </section>
      <aside className={s.summary}>
        <OrderSummary />
        {shouldShowMinimumOrderAmount && <MinimumOrderAmount />}
        <CheckoutButtons />
        <SecureCheckout />
      </aside>
    </main>
  );
};
