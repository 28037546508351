import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import {SectionNotification} from 'wix-ui-tpa';
import Error from 'wix-ui-icons-common/on-stage/Error';
import s from './OutOfStock.scss';
import {useTranslation} from 'yoshi-flow-editor-runtime';

export const OutOfStock = () => {
  const {t} = useTranslation();

  return (
    <div className={s.root} data-hook={CartItemDataHook.OutOfStockRoot}>
      <SectionNotification type="error" className={s.error} data-hook={CartItemDataHook.OutOfStockError}>
        <SectionNotification.Icon icon={<Error />} />
        <SectionNotification.Text>{t('cart.errors.item_out_of_stock')}</SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};
