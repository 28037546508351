import React, {useEffect, useState} from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa';
import classNames from 'classnames';
import {classes} from './DeliveryMethod.st.css';
import s from './DeliveryMethod.scss';
import {useControllerProps} from '../../../../ControllerContext';
import {useTranslation, TranslationFunction} from 'yoshi-flow-editor-runtime';
import {IDeliveryAddress} from '@wix/wixstores-graphql-schema';
import {ShippingRuleOptionModel} from '../../../../../../../domain/services/OrderService';
import _ from 'lodash';

const getTitle = (t: TranslationFunction, option: ShippingRuleOptionModel): any => {
  if (!option.isPickup) {
    return `${option.title} - ${option.formattedRate}`;
  }

  let description;
  const address = option.pickupInfo.address;

  const omitExtraFields = (data: IDeliveryAddress) => _.omit(data, ['countryName', 'subdivisionName']);

  if (address.countryName) {
    let data = omitExtraFields({...address, country: address.countryName});
    description = t('cart.delivery_method_picker.pickup_address_without_subdivision', data);

    if (address.subdivisionName) {
      data = omitExtraFields({...data, subdivision: address.subdivisionName});
      description = t('cart.delivery_method_picker.pickup_address', data);
    }

    return (
      <>
        {option.title} - <span data-hook="dropdown-description">{description}</span>
      </>
    );
  }

  return `${option.title}`;
};

const getOptions = (t: TranslationFunction, shippingRuleOptions: ShippingRuleOptionModel[]) => {
  return shippingRuleOptions.map((option) => {
    const shouldShowSubtitle = !option.isPickup && option.deliveryTime;
    const dropdownOption: DropdownOptionProps = {
      id: option.id,
      value: getTitle(t, option),
      isSelectable: true,
    };
    if (shouldShowSubtitle) {
      dropdownOption.subtitle = option.deliveryTime;
    }
    return dropdownOption;
  });
};

export const DeliveryMethod = () => {
  const {t} = useTranslation();
  const [dropDownKey, setDropDownKey] = useState(0);
  const {cart} = useControllerProps().cartStore;
  const {selectShippingOption, shippingRuleOptions} = useControllerProps().orderStore;

  useEffect(() => {
    setDropDownKey(dropDownKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingRuleOptions]);

  const getSelectedItemTooltip = () => {
    const selectedOption = shippingRuleOptions.find((option) => option.id === cart.selectedShippingOption.id);
    return getTitle(t, selectedOption);
  };

  return (
    <div className={s.root} data-hook={OrderSummaryDataHook.DeliveryMethodRoot} title={getSelectedItemTooltip()}>
      <dl>
        <dt className={s.dropDownContainer}>
          <Dropdown
            data-hook={OrderSummaryDataHook.DeliveryMethodDropdown}
            options={getOptions(t, shippingRuleOptions)}
            initialSelectedId={cart.selectedShippingOption.id}
            upgrade={true}
            onChange={(selectedOption: DropdownOptionProps) => selectShippingOption(selectedOption.id)}
            className={classNames(classes.dropdown, s.dropDown)}
            placement={'bottom'}
            key={dropDownKey}
          />
        </dt>
      </dl>
    </div>
  );
};
